import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import AnimalPage from "../components/animal-page"
import SEO from "../components/seo"

export const query = graphql`
  query AnimalTemplateQuery($id: String!) {
    animalPage: sanityAnimal(id: { eq: $id }) {
      id
      image {
        asset {
          _id
        }
      }
      alt
      name
      type
      slug {
        current
      }
      _rawContent
    }
  }
`

const AnimalTemplate = ({ data }) => {
  const animal = data && data.animalPage
  return (
    <Layout>
      <SEO
        title={`Meet ${animal.name}`}
        description={`Find out all about our ${animal.type}`}
      />
      {animal && <AnimalPage {...animal} />}
    </Layout>
  )
}

export default AnimalTemplate
