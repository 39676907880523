import React from "react"
import BlockText from "./block-text"

import animalPageStyles from "../styles/animal-page.module.scss"

import { buildImageObj } from "../utils/helpers"
import { imageUrlFor } from "../utils/image-url"

const ServicePage = props => {
  return (
    <article className={animalPageStyles.container}>
      <h1>{props.name}</h1>
      <h2>The {props.type}</h2>
      {props.image && props.image.asset && (
        <img
          src={imageUrlFor(buildImageObj(props.image))
            .width(500)
            .maxWidth(500)
            .maxHeight(300)
            .url()}
          //Add proper alt tags to sanity studio
          alt={props.alt}
        />
      )}

      {props._rawContent && <BlockText blocks={props._rawContent} />}
    </article>
  )
}

export default ServicePage
